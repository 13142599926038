import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../helpers/base_url";
import './estate-detail.css'

function EstateDetail() {
  // const params = useParams();

  // This gets the id specifically
  const { id } = useParams();
  const [estate, setEstate] = useState<any>({})
  const [precautionaryTips, setPrecautionaryTips] = useState<any>([])
  useEffect(() => {
    fetch(`${API_BASE_URL}/estate?estate_id=${id}`)
      .then(res => res.json())
      .then(data => {
        console.log('Parsed data:', data);
        setEstate(data?.data); // Assuming data is already the array of estates
      })
      .catch(err => console.error('Fetch error:', err));

    // fetch precautionary tips
    fetch(`${API_BASE_URL}/precautionary-tip`)
      .then(res => res.json())
      .then(data => {
        console.log('Parsed data:', data);
        setPrecautionaryTips(data?.data); // Assuming data is already the array of estates
      })
      .catch(err => console.error('Fetch error:', err));
  }, []);

  return (
    <>
      <div className="full-row pt-0" style={{ marginTop: "9rem" }}>
        <div className="container">
          <div className="row g-5" style={{ alignItems: "center" }}>
            <div className="col-xl-6 order-xl-1">

              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    backgroundColor: "#510000",
                    width: "1rem",
                    height: "11rem",
                    marginRight: "2rem",
                    marginTop: "6rem"
                  }}
                ></div>

                <div>
                  <h3
                    className="text-black header-text"
                  >
                    {estate?.name}
                  </h3>

                  <div>
                    <p
                      style={{
                        fontFamily: "Lato !important",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "25px",
                        letterSpacing: "0%",
                        marginBottom: "10px",
                        color: '#000'
                      }}
                    >
                      {estate?.description}
                    </p>

                    <a
                      href={`/buy-estate-land/${estate.id}`}
                      className="btn btn-white add-listing-btn"
                      style={{
                        marginTop: "1rem",
                        borderRadius: "60px",
                        color: "#fff",
                        backgroundColor: "#510000",
                        padding: "5px 60px",
                      }}
                    >
                      Buy Now
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-6 order-xl-2">
              <img
                width="613"
                height="594"
                src={estate.imageUrl}
                className="ls-bg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="full-row pt-0" style={{ marginTop: "2rem" }}>
        <div className="container">
          <div className="row">
            <div className="col-xl-6 order-xl-1">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3940.2005332260305!2d7.473392875899397!3d9.045464491016535!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104e0b749be6f5b1%3A0x5b9c84ab24838a88!2sYakubu%20Gowon%20Centre!5e0!3m2!1sen!2sng!4v1741702419432!5m2!1sen!2sng" width="600" height="1000" style={{ border: 0 }} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
              <div className="mt-5">
                <h3
                  className="text-secondary"
                  style={{
                    fontFamily: "Poppins !important",
                    fontSize: "24px",
                    fontWeight: "700",
                    lineHeight: "16px",
                  }}
                >
                  Precautionary Tip
                </h3>

                {precautionaryTips.map((precautionaryTip: any, index: number) => (
                  <p
                    key={precautionaryTip.id || index}
                    style={{
                      fontFamily: "Poppins !important",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "24px",
                      letterSpacing: "0%",
                      marginBottom: "10px",
                      color: '#000'
                    }}
                  >
                    <span className="mr-5">{index + 1}.</span>{precautionaryTip?.tip}
                  </p>
                ))}

                {/* <p
                  style={{
                    fontFamily: "Poppins !important",
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "24px",
                    letterSpacing: "0%",
                    marginBottom: "10px",
                  }}
                >
                  <span className="mr-5">2.</span>Only pay Rental fee, Sales fee
                  or any upfront payment after you verify the Landlord.
                </p>
                <p
                  style={{
                    fontFamily: "Poppins !important",
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "24px",
                    letterSpacing: "0%",
                    marginBottom: "10px",
                  }}
                >
                  <span className="mr-5">3.</span>Ensure you meet the Agent in
                  an open location.
                </p>
                <p
                  style={{
                    fontFamily: "Poppins !important",
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "24px",
                    letterSpacing: "0%",
                    marginBottom: "10px",
                  }}
                >
                  <span className="mr-5">4.</span>The Agent does not represent
                  Landbank and Landbank is not liable for any monetary
                  transaction between you and the Agent.
                </p> */}
              </div>
            </div>

            {(estate?.propertyTemplates || []).map((propertyTemplate: any, index: number) => (
              <div key={propertyTemplate.id || index} className="col-xl-6 order-xl-2">
                <div className="property-overview border summary rounded bg-white p-10 mb-50">
                  <div className="row">
                    <div className="col-md-12">
                      <div
                        id="single-property"
                        style={{
                          margin: "0 auto 30px",
                        }}
                      >
                        <div data-ls="duration:7500; transition2d:5; kenburnszoom:in; kenburnsscale:1.2;">
                          {" "}
                          <img
                            width="800"
                            height="400"
                            src={propertyTemplate.images[0]?.imageUrl}
                            className="ls-bg"
                            alt=""
                          />{" "}
                        </div>

                        <div
                          className="ls-slide"
                          data-ls="duration:7500; transition2d:5; kenburnszoom:out; kenburnsscale:1.2;"
                        >
                          {" "}
                          <img
                            width="1920"
                            height="1280"
                            src="./assets/images/slider/6.png"
                            className="ls-bg"
                            alt=""
                          />{" "}
                        </div>

                        <div
                          className="ls-slide"
                          data-ls="duration:7500; transition2d:5; kenburnszoom:in; kenburnsscale:1.2;"
                        >
                          {" "}
                          <img
                            width="1920"
                            height="1280"
                            src="./assets/images/slider/8.png"
                            className="ls-bg"
                            alt=""
                          />{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-auto">
                      <h4
                        className="listing-title"
                        style={{
                          fontFamily: "Inter !important",
                          fontSize: "24px !important",
                          fontWeight: "600 !important",
                          lineHeight: "29px !important",
                        }}
                      >
                        <a href="property-single-v1.html">
                          {propertyTemplate?.templateName}
                        </a>
                      </h4>
                    </div>
                  </div>
                  <div className="row row-cols-1">
                    <div className="col">
                      <p
                        style={{
                          fontFamily: "Poppins !important",
                          fontSize: "16px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          color: "#000",
                        }}
                        dangerouslySetInnerHTML={{ __html: propertyTemplate?.description }}
                      >
                      </p>
                    </div>
                  </div>

                  <div className="col-12">
                    <hr />
                    <div className="product-offers mt-2">
                      <ul className="product-offers-list">
                        {propertyTemplate?.facilityItems.map((facility: any, index: number) => (
                          <li key={facility.id || index} className="product-offer-item text-black">
                            {" "}
                            {facility?.propertyFacility?.name}
                          </li>
                        ))}
                        <li className="product-offer-item text-black">
                          {" "}
                          Asking Price:{" "}
                          <strong>NGN {propertyTemplate?.prices[0]?.price} ( {propertyTemplate?.negotiationStatus?.name})</strong>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <ul className="quick-meta mt-4">
                    <li className="w-auto">
                      <a
                        href={`/buy-land/${propertyTemplate.id}`}
                        // href={`/buy-land`}
                        className="btn btn-white text-primary"
                        style={{
                          marginLeft: "1rem",
                          borderRadius: "60px",
                          border: "1px solid #510000",
                        }}
                      >
                        Buy Now
                      </a>
                    </li>

                    <li className="w-auto">
                      <a
                        href="#"
                        className="btn btn-white text-primary"
                        style={{
                          marginLeft: "1rem",
                          borderRadius: "60px",
                          border: "1px solid #510000",
                        }}
                      >
                        Share
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            ))}

          </div>
        </div>
      </div>

      <div className="full-row bg-light">
        <div className="container">
          <div className="row lg-w-50 md-w-80 sm-w-100">
            <div className="col mb-5">
              <p
                className="text-start faq-sub-header-text"
              >
                FREQUENTLY ASKED QUESTIONS
              </p>
              <h2
                className="down-line w-100 mx-auto mb-4 text-start w-sm-100 faq-header-text"
              >
                We Understand your Concerns
              </h2>
              <span
                className="d-table w-80 w-sm-100 sub-title general-font text-dark fw-normal text-start"
                style={{
                  fontFamily: "Poppins !important",
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                Trust Landbankng ltd for your real estate transactions.
                Experience the future of secure and transparent escrow services
              </span>
            </div>
            <div className="bb-accordion ac-single-show accordion-plus-left">
              <div className="ac-card">
                <a className="ac-toggle text-dark text-truncate active" href="#">
                  Website and Mobile App Design
                </a>
                <div className="ac-collapse show" style={{ display: "block" }}>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum.
                  </p>
                </div>
              </div>
              <div className="ac-card">
                <a className="ac-toggle text-dark text-truncate" href="#">
                  Motion Graphics and Animation
                </a>
                <div className="ac-collapse">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum.
                  </p>
                </div>
              </div>
              <div className="ac-card">
                <a className="ac-toggle text-dark text-truncate" href="#">
                  User Experience and Brand Strategy
                </a>
                <div className="ac-collapse">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EstateDetail;
