import './our-services.css'

function OurServices() {
  return (
    <><div className="full-row">
      <div className="container">
        <div className="row">
          <div className="col mb-5">
            <p
              className="text-center"
              style={{
                color: "#0A0A0A",
                fontFamily: "Abhaya Libre ExtraBold",
                fontWeight: "800",
                fontSize: "24px",
                lineHeight: "32px",
                letterSpacing: "0%",
              }}
            >
              Our Services:
            </p>
            <h2
              className="down-line w-100 mx-auto mb-4 text-center w-sm-100 header-text"
            >
              We are the BEST in property acquisitions
            </h2>
            <span
              className="d-table w-50 w-sm-100 sub-title general-font text-dark fw-normal mx-auto text-center"
              style={{ fontFamily: "Poppins !important" }}
            >
              At CADLANDS, we offer more than property sales; we deliver complete real estate solutions aimed at making property ownership, investment, and management smooth, safe, and lucrative.
            </span>
          </div>
        </div>
        <div className="row row-cols-lg-2 row-cols-sm-1 g-1 justify-content-left">
          {/* <div className="row justify-content-left services-grid"> */}
          <div >
            <a
              href="#"
              className="text-left d-flex flex-column align-items-left hover-text-dark p-4 bg-white transation text-general h-50"
            >
              <div className="box-50px position-relative">
                <img
                  src="assets/images/icon/portfolio-management-icon.png"
                  alt="Image Not Found!"
                />
              </div>
              <h6
                className="d-block text-secondary"
                style={{
                  fontFamily: "Poppins !important",
                  fontWeight: "800",
                  fontSize: "18px",
                  lineHeight: "30px",
                  letterSpacing: "1%",
                  textAlign: "left",
                  margin: "0.8rem 0rem",
                }}
              >
                Portfolio Management
              </h6>
              <p
                style={{
                  fontFamily: "Poppins !important",
                  color: "#000",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "22px",
                }}
              >
                Real estate investment offers a reliable pathway to financial growth, but managing multiple properties can be challenging. At CADLANDS, we assist individuals and investors in strategically building, managing, and optimising their real estate portfolios. Our comprehensive services include:
                <br />
                ✔ Identifying and securing lucrative investment opportunities.<br />
                ✔ Handling property acquisition and the necessary documentation.<br />
                ✔ Offering expert advice to maximize returns and minimize risks.<br />
                ✔ Monitoring investment performance and keeping an eye on market trends.
              </p>
            </a>
          </div>
          <div >
            <a
              href="#"
              className="text-left d-flex flex-column align-items-left hover-text-dark p-4 bg-white transation text-general h-50"
            >
              <div className="box-50px position-relative">
                <img
                  src="assets/images/icon/deal-brokerage.png"
                  alt="Image Not Found!"
                />
              </div>
              <h6
                className="d-block text-secondary"
                style={{
                  fontFamily: "Poppins !important",
                  fontWeight: "800",
                  fontSize: "18px",
                  lineHeight: "30px",
                  letterSpacing: "1%",
                  textAlign: "left",
                  margin: "0.8rem 0rem",
                }}
              >
                Deal Brokerage
              </h6>
              <p
                style={{
                  fontFamily: "Poppins !important",
                  color: "#000",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "22px",
                }}
              >
                :Navigating land transactions can be challenging, but with CADLANDS as your reliable intermediary, buying or selling land becomes a smooth and secure process. We serve as advisers and brokers, guiding buyers and sellers toward fair agreements while ensuring transparency and legal integrity in all transactions. Our brokerage services include:<br />
                ✔ Verifying land documents—confirming their authenticity and legal status.<br />
                ✔ Assessing property value—providing an accurate evaluation of the land's worth.<br />
                ✔ Conducting due diligence—performing thorough background checks.<br />
                ✔ Facilitating document transfers—ensuring seamless ownership transitions.
              </p>
            </a>
          </div>
          <div >
            <a
              href="#"
              className="text-left d-flex flex-column align-items-left hover-text-dark p-4 bg-white transation text-general h-50"
            >
              <div className="box-50px position-relative">
                <img
                  src="assets/images/icon/land-sales.png"
                  alt="Image Not Found!"
                />
              </div>
              <h6
                className="d-block text-secondary"
                style={{
                  fontFamily: "Poppins !important",
                  fontWeight: "800",
                  fontSize: "18px",
                  lineHeight: "30px",
                  letterSpacing: "1%",
                  textAlign: "left",
                  margin: "0.8rem 0rem",
                }}
              >
                Land Sales
              </h6>
              <p
                style={{
                  fontFamily: "Poppins !important",
                  color: "#000",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "22px",
                }}
              >
                Land ownership is the foundation of real estate wealth, and at CADLANDS, we make it accessible, secure, and rewarding. Our primary service is the buying and selling of land within our exclusive, well-planned estates. We offer flexible payment plans, clear documentation, and a stress-free acquisition process, ensuring you can invest in land with confidence.
              </p>
            </a>
          </div>
          <div>
            <a
              href="#"
              className="text-left d-flex flex-column align-items-left hover-text-dark p-4 bg-white transation text-general h-50"
            >
              <div className="box-50px position-relative">
                <img
                  src="assets/images/icon/real-estate-consultancy.png"
                  alt="Image Not Found!"
                />
              </div>
              <h6
                className="d-block text-secondary"
                style={{
                  fontFamily: "Poppins !important",
                  fontWeight: "800",
                  fontSize: "18px",
                  lineHeight: "30px",
                  letterSpacing: "1%",
                  textAlign: "left",
                  margin: "0.8rem 0rem",
                }}
              >
                Real Estate Consultancy
              </h6>
              <p
                style={{
                  fontFamily: "Poppins !important",
                  color: "#000",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "22px",
                }}
              >
                The Nigerian real estate market can be complex, but with the right support, it opens up a world of possibilities. Our consulting services offer expert insights, strategies, and personalised advice for individuals, investors, and corporations. We help with:<br />
                ✔ Investment analysis—assessing risks and potential returns.<br />
                ✔ Market research—spotting prime locations and emerging trends.<br />
                ✔ Regulatory guidance—navigating the complexities of Nigeria’s real estate laws and policies.<br />
                ✔ Deal structuring—ensuring transactions are both profitable and legally sound
              </p>
            </a>
          </div>
          <div>
            <a
              href="#"
              className="text-left d-flex flex-column align-items-left hover-text-dark p-4 bg-white transation text-general h-50"
            >
              <div className="box-50px position-relative">
                <img
                  src="assets/images/icon/estate-management.png"
                  alt="Image Not Found!"
                />
              </div>
              <h6
                className="d-block text-secondary"
                style={{
                  fontFamily: "Poppins !important",
                  fontWeight: "800",
                  fontSize: "18px",
                  lineHeight: "30px",
                  letterSpacing: "1%",
                  textAlign: "left",
                  margin: "0.8rem 0rem",
                }}
              >
                Estate Management
              </h6>
              <p
                style={{
                  fontFamily: "Poppins !important",
                  color: "#000",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "22px",
                }}
              >
                In addition to developing our company estates, we offer our expertise in managing estates for partners within our operational regions. Our focus is on efficiency, organisation, and delivering long-term value. Our estate management services include:<br />
                ✔ Ensuring smooth operations and fostering community development.<br />
                ✔ Implementing effective property maintenance and security measures.<br />
                ✔ Managing tenant relations and keeping facilities in prime condition.<br />
                ✔ Overseeing financial matters, which includes handling service charge administration.
              </p>
            </a>
          </div>

        </div>
      </div>
    </div></>
  )
}

export default OurServices