import './aboutus.css'

function AboutUs() {
    return (
        <div className="full-row">
            <div className="container">
                <div className="row">
                    <div className="about-us-grid">
                        <div>
                            <div className="text-secondary text-center mb-5">
                                <h4
                                    className="text-secondary text-start mb-2"
                                    style={{
                                        fontFamily: "Abhaya Libre ExtraBold",
                                        fontWeight: "800",
                                        fontSize: "24px",
                                        lineHeight: "32px",
                                        letterSpacing: "0%",
                                    }}
                                >
                                    ABOUT US
                                </h4>
                                <h2
                                    className="text-secondary text-start mb-4 header-text"
                                >
                                    We turn your dream <br />
                                    <span className="text-primary">properties</span> into reality
                                </h2>
                                <span
                                    className="d-table text-start"
                                    style={{
                                        fontFamily: "Poppins !important",
                                        fontWeight: "400",
                                        fontSize: "16px",
                                        lineHeight: "23px",
                                        letterSpacing: "1%",
                                    }}
                                >
                                    As a premier real estate firm, we take pride in our commitment
                                    to providing exceptional service, unwavering integrity, and
                                    unparalleled expertise to our clients.
                                </span>

                                <h2
                                    className="text-primary text-start mb-4"
                                    style={{
                                        fontFamily: "Poppins !important",
                                        fontSize: "32px",
                                        fontWeight: "600",
                                        lineHeight: "16px",
                                        marginTop: "3rem",
                                    }}
                                >
                                    Our Mission
                                </h2>
                                <span
                                    className="d-table text-start"
                                    style={{
                                        fontFamily: "Poppins !important",
                                        fontWeight: "400",
                                        fontSize: "16px",
                                        lineHeight: "23px",
                                        letterSpacing: "1%",
                                    }}
                                >
                                    At Cadlands, our mission is to empower individuals and
                                    businesses to achieve their real estate dreams while building
                                    lasting legacies. We are committed to providing unparalleled
                                    service, leveraging our expertise to navigate the complexities
                                    of purchases within the real estate market.
                                </span>
                                <h2
                                    className="text-primary text-start mb-4"
                                    style={{
                                        fontFamily: "Poppins !important",
                                        fontSize: "32px",
                                        fontWeight: "600",
                                        lineHeight: "16px",
                                        marginTop: "3rem",
                                    }}
                                >
                                    Our Vision
                                </h2>
                                <span
                                    className="d-table text-start"
                                    style={{
                                        fontFamily: "Poppins !important",
                                        fontWeight: "400",
                                        fontSize: "16px",
                                        lineHeight: "23px",
                                        letterSpacing: "1%",
                                    }}
                                >
                                    At Cadlands, our mission is to empower individuals and
                                    businesses to achieve their real estate dreams while building
                                    lasting legacies. We are committed to providing unparalleled
                                    service, leveraging our expertise to navigate the complexities
                                    of purchases within the real estate market.
                                </span>
                            </div>

                            <div className="text-left" style={{ marginTop: "1rem" }}>
                                <a
                                    href="#"
                                    className="btn btn-white add-listing-btn"
                                    style={{
                                        borderRadius: "60px",
                                        color: "#fff",
                                        backgroundColor: "#510000",
                                        padding: "5px 60px",
                                    }}
                                >
                                    Read More
                                </a>
                            </div>
                        </div>

                        <div>
                            <img
                                src="assets/images/about-us-img.png"
                                alt="Image Not Found!"
                            />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default AboutUs