export const formatNumber = (
  amount: number,
  {
    currencyCode = "NGN",
    locale = "en-NG",
    abbreviate = false,
    decimals = 2,
  } = {}
) => {
  // Handle edge cases
  if (amount === null || amount === undefined || isNaN(amount)) {
    return `${currencyCode} 0.00`;
  }

  // If not abbreviating, use standard currency formatting
  if (!abbreviate) {
    try {
      return new Intl.NumberFormat(locale, {
        style: "currency",
        currency: currencyCode,
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
      }).format(amount);
    } catch (error) {
      console.error("Error formatting currency:", error);
      return `${currencyCode} ${amount.toFixed(decimals)}`;
    }
  }

  // Get currency symbol
  let symbol = "";
  try {
    symbol = new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currencyCode,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
      .format(0)
      .replace(/0/g, "")
      .trim();
  } catch (error) {
    symbol = currencyCode + " ";
  }

  // Abbreviate based on magnitude
  const absAmount = Math.abs(amount);
  let formattedValue;
  let abbrev = "";

  if (absAmount >= 1000000000) {
    formattedValue = (amount / 1000000000).toFixed(decimals);
    abbrev = "B";
  } else if (absAmount >= 1000000) {
    formattedValue = (amount / 1000000).toFixed(decimals);
    abbrev = "M";
  } else if (absAmount >= 1000) {
    formattedValue = (amount / 1000).toFixed(decimals);
    abbrev = "K";
  } else {
    formattedValue = amount.toFixed(decimals);
  }

  // Remove trailing zeros after decimal point
  formattedValue = formattedValue.replace(/\.?0+$/, "") || formattedValue;

  return `${symbol}${formattedValue}${abbrev}`;
};

export const parseFormattedNumber = (
  formattedValue: string,
  { currencyCode = "NGN", locale = "en-NG" } = {}
): number => {
  // Return 0 for null, undefined, or empty strings
  if (!formattedValue) {
    return 0;
  }

  // Get currency symbol to remove it from the string
  let symbol = "";
  try {
    symbol = new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currencyCode,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
      .format(0)
      .replace(/0/g, "")
      .trim();
  } catch (error) {
    symbol = currencyCode + " ";
  }

  // Clean the input string by removing currency symbols, commas, and spaces
  let cleanedValue = formattedValue
    .replace(new RegExp(symbol, "g"), "")
    .replace(new RegExp(currencyCode, "gi"), "")
    .replace(/,/g, "")
    .replace(/\s/g, "")
    .trim();

  // Handle abbreviations (K, M, B)
  let multiplier = 1;
  if (cleanedValue.endsWith("K") || cleanedValue.endsWith("k")) {
    multiplier = 1000;
    cleanedValue = cleanedValue.slice(0, -1);
  } else if (cleanedValue.endsWith("M") || cleanedValue.endsWith("m")) {
    multiplier = 1000000;
    cleanedValue = cleanedValue.slice(0, -1);
  } else if (cleanedValue.endsWith("B") || cleanedValue.endsWith("b")) {
    multiplier = 1000000000;
    cleanedValue = cleanedValue.slice(0, -1);
  }

  // Convert to number and apply multiplier
  const parsedValue = parseFloat(cleanedValue);

  // Return 0 if the result is NaN
  if (isNaN(parsedValue)) {
    return 0;
  }

  return parsedValue * multiplier;
};