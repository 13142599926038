import React from "react";
import withRouter from "./Common/withRouter";
import Footer from "./Footer";
import Header from "./Header";

const NonAuthLayout = (props: any) => {
  const { layoutType, children } = props;
  console.log("🚀 ~ NonAuthLayout ~ layoutType:", layoutType);

  return (
    <React.Fragment>
      <div id="page_wrapper">
        <Header />
        {children}
        <Footer />
      </div>
    </React.Fragment>
  );
};

// NonAuthLayout.propTypes = {
//   children: PropTypes.any,
//   location: PropTypes.object,
//   layoutType: PropTypes.string, // Add layoutType as a prop
// };

NonAuthLayout.defaultProps = {
  layoutType: "default", // Default layout type if not provided
};

export default withRouter(NonAuthLayout);
