const getBaseUrl = () => {
  const hostname = window.location.hostname;
  if (hostname === "localhost" || hostname === "127.0.0.1") {
    return "http://localhost:3007";
  } else if (hostname.includes("cadlands.com")) {
    return "https://cadlands-api.onrender.com";
  }
  //   else {
  //     return "https://cadlands-api.onrender.com";
  //   }
};

export const API_BASE_URL = getBaseUrl();
