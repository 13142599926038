function OtherListing() {
    return (
        // <><div className="full-row bg-light">
        //     <div className="container">
        //         <div className="row" style={{ marginBottom: "5rem" }}>
        //             <div className="col mb-9">
        //                 <div className="align-items-center">
        //                     <div className="text-center">
        //                         <span
        //                             className="text-center"
        //                             style={{
        //                                 color: "#000",
        //                                 fontFamily: "Abhaya Libre ExtraBold",
        //                                 fontWeight: "800",
        //                                 fontSize: "24px",
        //                                 lineHeight: "32px",
        //                                 letterSpacing: "0%",
        //                             }}
        //                         >
        //                             Want More?
        //                         </span>
        //                         <h2
        //                             className="text-center"
        //                             style={{
        //                                 fontFamily: "Abhaya Libre ExtraBold",
        //                                 fontSize: "48px",
        //                                 fontWeight: "800",
        //                                 lineHeight: "60px",
        //                             }}
        //                         >
        //                             Other Listings
        //                         </h2>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //         <div className="row row-cols-md-3 row-cols-1 g-4">
        //             <div
        //                 className="col bg-white"
        //                 style={{
        //                     boxShadow:
        //                         "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
        //                     padding: "15px",
        //                     borderRadius: "10px",
        //                     width: "31.33%",
        //                     margin: "10px",
        //                 }}
        //             >
        //                 {/* <!-- Property Grid -->  */}
        //                 <div className="property-grid-1 property-block transation-this">
        //                     <div
        //                         className="overflow-hidden position-relative transation thumbnail-img bg-secondary hover-img-zoom"
        //                         style={{ borderRadius: "5px" }}
        //                     >
        //                         <a href="property-single-v1.html">
        //                             <img
        //                                 src="assets/images/property_grid/property-grid-1.png"
        //                                 alt="Image Not Found!"
        //                             />
        //                         </a>
        //                     </div>
        //                     <div className="property_text pt-4" style={{ paddingLeft: "5px" }}>
        //                         <span className="listing-title">Genesis City</span>
        //                         <span className="listing-text">
        //                             4bedroom terraced duplex with a room bq in an estate off
        //                             guzape road, maitama, Abuja.
        //                         </span>
        //                         <span className="listing-price">NGN 8,500,000</span>
        //                         <span className="listing-location" style={{ margin: "0px" }}>
        //                             <i className="fas fa-map-marker-alt"></i> Awka, Anambra State
        //                         </span>
        //                         <span className="listing-location" style={{ margin: "0px" }}>
        //                             <i className="fas fa-map-marker-alt"></i> Tittle: Govt Approval
        //                         </span>
        //                         <span className="listing-location" style={{ margin: "0px" }}>
        //                             <i className="fas fa-map-marker-alt"></i> Size: 465 SQM
        //                         </span>
        //                     </div>
        //                     <div className="d-flex align-items-center post-meta mt-2 py-3 px-4">
        //                         <div className="view-details ms-auto">
        //                             <span>
        //                                 click to see more details{" "}
        //                                 <i className="fas fa-arrow-right"></i>
        //                             </span>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //             <div
        //                 className="col bg-white"
        //                 style={{
        //                     boxShadow:
        //                         "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
        //                     padding: "15px",
        //                     borderRadius: "5px",
        //                     width: "31.33%",
        //                     margin: "10px",
        //                 }}
        //             >
        //                 {/* <!-- Property Grid -->  */}
        //                 <div className="property-grid-1 property-block transation-this">
        //                     <div className="overflow-hidden position-relative transation thumbnail-img bg-secondary hover-img-zoom">
        //                         <a href="property-single-v1.html">
        //                             <img
        //                                 src="assets/images/property_grid/property-grid-1.png"
        //                                 alt="Image Not Found!"
        //                             />
        //                         </a>
        //                     </div>
        //                     <div className="property_text pt-4" style={{ paddingLeft: "5px" }}>
        //                         <span className="listing-title">Genesis City</span>
        //                         <span className="listing-text">
        //                             4bedroom terraced duplex with a room bq in an estate off
        //                             guzape road, maitama, Abuja.
        //                         </span>
        //                         <span className="listing-price">NGN 8,500,000</span>
        //                         <span className="listing-location" style={{ margin: "0px" }}>
        //                             <i className="fas fa-map-marker-alt"></i> Awka, Anambra State
        //                         </span>
        //                         <span className="listing-location" style={{ margin: "0px" }}>
        //                             <i className="fas fa-map-marker-alt"></i> Tittle: Govt Approval
        //                         </span>
        //                         <span className="listing-location" style={{ margin: "0px" }}>
        //                             <i className="fas fa-map-marker-alt"></i> Size: 465 SQM
        //                         </span>
        //                     </div>
        //                     <div className="d-flex align-items-center post-meta mt-2 py-3 px-4">
        //                         <div className="view-details ms-auto">
        //                             <span>
        //                                 click to see more details{" "}
        //                                 <i className="fas fa-arrow-right"></i>
        //                             </span>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //             <div
        //                 className="col bg-white"
        //                 style={{
        //                     boxShadow:
        //                         "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
        //                     padding: "15px",
        //                     borderRadius: "5px",
        //                     width: "32.33%",
        //                     margin: "10px",
        //                 }}
        //             >
        //                 {/* <!-- Property Grid -->  */}
        //                 <div className="property-grid-1 property-block transation-this">
        //                     <div className="overflow-hidden position-relative transation thumbnail-img bg-secondary hover-img-zoom">
        //                         <a href="property-single-v1.html">
        //                             <img
        //                                 src="assets/images/property_grid/property-grid-1.png"
        //                                 alt="Image Not Found!"
        //                             />
        //                         </a>
        //                     </div>
        //                     <div className="property_text pt-4" style={{ paddingLeft: "5px" }}>
        //                         <span className="listing-title">Genesis City</span>
        //                         <span className="listing-text">
        //                             4bedroom terraced duplex with a room bq in an estate off
        //                             guzape road, maitama, Abuja.
        //                         </span>
        //                         <span className="listing-price">NGN 8,500,000</span>
        //                         <span className="listing-location" style={{ margin: "0px" }}>
        //                             <i className="fas fa-map-marker-alt"></i> Awka, Anambra State
        //                         </span>
        //                         <span className="listing-location" style={{ margin: "0px" }}>
        //                             <i className="fas fa-map-marker-alt"></i> Tittle: Govt Approval
        //                         </span>
        //                         <span className="listing-location" style={{ margin: "0px" }}>
        //                             <i className="fas fa-map-marker-alt"></i> Size: 465 SQM
        //                         </span>
        //                     </div>
        //                     <div className="d-flex align-items-center post-meta mt-2 py-3 px-4">
        //                         <div className="view-details ms-auto">
        //                             <span>
        //                                 click to see more details{" "}
        //                                 <i className="fas fa-arrow-right"></i>
        //                             </span>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //         <div className="text-center" style={{ marginTop: "3rem" }}>
        //             <a
        //                 href="#"
        //                 className="btn btn-white add-listing-btn"
        //                 style={{
        //                     marginTop: "1rem",
        //                     borderRadius: "60px",
        //                     color: "#fff",
        //                     backgroundColor: "#510000",
        //                     padding: "5px 60px",
        //                 }}
        //             >
        //                 Load More
        //             </a>
        //         </div>
        //     </div>
        // </div></>

        <div className="full-row bg-light">
            <div className="container">
                {/* Section Title */}
                <div className="row mb-5">
                        <span
                            className="text-center"
                            style={{
                                color: "#000",
                                fontFamily: "Abhaya Libre ExtraBold",
                                fontWeight: "800",
                                fontSize: "24px",
                                lineHeight: "32px",
                                letterSpacing: "0%",
                                textAlign: "center"
                            }}
                        >
                            Want More?
                        </span>
                    <div className="col-12">
                        <h2
                            className="text-center"
                            style={{
                                fontFamily: "Abhaya Libre ExtraBold, serif",
                                fontSize: "clamp(32px, 8vw, 48px)", // Responsive font size
                                fontWeight: "800",
                                lineHeight: "1.2", // Relative line height
                            }}
                        >
                            Other Listings
                        </h2>
                    </div>
                </div>

                {/* Property Grid */}
                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
                    {[1, 2, 3].map((item) => (
                        <div
                            key={item}
                            className="col"
                        >
                            <div
                                className="bg-white h-100"
                                style={{
                                    boxShadow: "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                                    padding: "15px",
                                    borderRadius: "10px",
                                }}
                            >
                                <div className="property-grid-1 property-block transation-this h-100 d-flex flex-column">
                                    {/* Property Image */}
                                    <div
                                        className="overflow-hidden position-relative transation thumbnail-img bg-secondary hover-img-zoom mb-3"
                                        style={{ borderRadius: "5px" }}
                                    >
                                        <a href="property-single-v1.html">
                                            <img
                                                src="assets/images/property_grid/property-grid-1.png"
                                                alt="Property"
                                                className="img-fluid w-100"
                                            />
                                        </a>
                                    </div>

                                    {/* Property Details */}
                                    <div className="property_text flex-grow-1 pt-2 px-2">
                                        <div className="d-flex flex-column gap-2">
                                            <h3
                                                className="listing-title fw-bold"
                                                style={{
                                                    fontSize: "clamp(20px, 5vw, 24px)",
                                                    marginBottom: "0.5rem"
                                                }}
                                            >
                                                Genesis City
                                            </h3>
                                            <p
                                                className="listing-text text-muted"
                                                style={{
                                                    fontSize: "clamp(14px, 4vw, 16px)",
                                                    marginBottom: "0.5rem"
                                                }}
                                            >
                                                4bedroom terraced duplex with a room bq in an estate off guzape road, maitama, Abuja.
                                            </p>
                                            <div className="d-flex flex-column gap-2">
                                                <span
                                                    className="listing-price fw-bold text-dark"
                                                    style={{
                                                        fontSize: "clamp(18px, 5vw, 22px)"
                                                    }}
                                                >
                                                    NGN 8,500,000
                                                </span>
                                                <div className="d-flex flex-column gap-1">
                                                    {[
                                                        { icon: "map-marker-alt", text: "Awka, Anambra State" },
                                                        { icon: "map-marker-alt", text: "Title: Govt Approval" },
                                                        { icon: "map-marker-alt", text: "Size: 465 SQM" }
                                                    ].map((detail, index) => (
                                                        <span
                                                            key={index}
                                                            className="listing-location d-flex align-items-center"
                                                            style={{
                                                                fontSize: "clamp(14px, 4vw, 16px)"
                                                            }}
                                                        >
                                                            <i className={`fas fa-${detail.icon} me-2`}></i>
                                                            {detail.text}
                                                        </span>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* View Details */}
                                    <div className="d-flex align-items-center post-meta mt-3 py-2 px-2">
                                        <div className="view-details ms-auto">
                                            <span
                                                className="d-flex align-items-center gap-2"
                                                style={{
                                                    fontSize: "clamp(14px, 4vw, 16px)"
                                                }}
                                            >
                                                Click to see more details
                                                <i className="fas fa-arrow-right"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Call to Action Button */}
                <div className="text-center mt-5">
                    <a
                        href="#"
                        className="btn btn-primary"
                        style={{
                            borderRadius: "60px",
                            padding: "0.5rem 2rem",
                            fontSize: "clamp(14px, 4vw, 16px)"
                        }}
                    >
                        Buy Now
                    </a>
                </div>
            </div>
        </div>
    )
}

export default OtherListing