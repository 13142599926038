import './our-gallery.css';
function OurGallery() {
    return (
        <div id="gallery" className="full-row" style={{ paddingTop: "2px" }}>
            <div className="container">
                <div className="row">
                    <div className="col mb-5">
                        <p className="text-start sub-header-text">
                            Our Gallery
                        </p>
                        <h2 className="down-line w-100 mx-auto mb-4 text-start w-sm-100 header-text">
                            We deliver the best
                        </h2>
                        <span
                            className="d-table w-50 w-sm-100 sub-title general-font text-dark fw-normal text-start"
                            style={{
                                fontFamily: "Poppins",
                                fontWeight: "400",
                                fontSize: "16px",
                                lineHeight: "24px",
                            }}
                        >
                            Just as our name implies. We provide a secure bank for all your
                            property acquisitions, inspect and verify properties, mediate
                            the transfer of funds, resolve disputes. we guarantee your peace
                            of mind.
                        </span>
                    </div>

                    <div
                        className="row gallery-grid-1"
                    >
                        <img
                            src="assets/images/gallery/gallery-img-1.png"
                            style={{ width: "800px", height: "200px" }}
                            alt="Image Not Found!"
                        />
                        <img
                            src="assets/images/gallery/gallery-img-2.png"
                            style={{ width: "400px", height: "200px" }}
                            alt="Image Not Found!"
                        />
                        <img
                            src="assets/images/gallery/gallery-img-3.png"
                            style={{ width: "400px", height: "200px" }}
                            alt="Image Not Found!"
                        />
                    </div>
                    <div
                        className="row gallery-grid-2"
                    >
                        <img
                            src="assets/images/gallery/gallery-img-6.png"
                            alt="Image Not Found!"
                            style={{ width: "400px", height: "200px" }}
                        />
                        <img
                            src="assets/images/gallery/gallery-img-5.png"
                            style={{ width: "800px", height: "200px" }}
                            alt="Image Not Found!"
                        />
                        <img
                            src="assets/images/gallery/gallery-img-4.png"
                            alt="Image Not Found!"
                            style={{ width: "400px", height: "200px" }}
                        />
                    </div>
                </div>

                <div className="row"></div>
            </div>
        </div>
    )
}

export default OurGallery