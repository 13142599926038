import './affiliate-program-section.css';
function AffiliateProgramSection() {
    return (
        <><div className="full-row bg-light">
            <div className="container">
                <div className="row affilate-program-section">
                    <div>
                        <img
                            src="assets/images/affiliate-program/affiliate-program-img.png"
                            alt="Image Not Found!"
                        />
                    </div>
                    <div>
                        <div className="text-secondary text-center mb-5">
                            <h2
                                className="text-secondary text-start mb-4"
                                style={{
                                    
                                }}
                            >
                                Welcome to Cadlands Affiliate Program
                            </h2>

                            <h2
                                className="text-black text-start mb-1"
                                style={{
                                    fontFamily: "Poppins !important",
                                    fontWeight: "500",
                                    fontSize: "24px",
                                    lineHeight: "35px",
                                    marginTop: "1rem",
                                }}
                            >
                                About the program
                            </h2>
                            <span
                                className="d-table sub-title text-start"
                                style={{
                                    fontFamily: "Poppins !important",
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    lineHeight: "24px",
                                    letterSpacing: "0%",
                                }}
                            >
                                Welcome to CADLANDS Affiliate Program, a robust system designed to help our affiliates earn more from real estate. We are a real estate firm with the global stage in mind.
                                Would you love to join us and be a part of that dream? We have developing estates for sale, where you make up to 5-15% commission per sales when you refer someone to buy from us.
                                For instance, if you refer a client who purchases a plot of land worth 6.5 million from us, this earns you a whopping N650,000 from a single sale
                            </span>

                            <h2
                                className="text-black text-start mb-1"
                                style={{
                                    fontFamily: "Poppins !important",
                                    fontWeight: "500",
                                    fontSize: "24px",
                                    lineHeight: "24px",
                                    marginTop: "1rem",
                                }}
                            >
                                How it works
                            </h2>
                            <ul
                                style={{
                                    listStyleType: "disc !important",
                                    paddingLeft: "15px",
                                }}
                            >
                                <li>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <span>
                                            <i className="fs-4">&bull;</i>
                                        </span>
                                        <p
                                            style={{
                                                marginLeft: "1rem",
                                                textAlign: "left",
                                                fontFamily: "Poppins !important",
                                                fontWeight: "400",
                                                fontSize: "14px",
                                                lineHeight: "24px",
                                                letterSpacing: "0%",
                                            }}
                                        >
                                            Visit ca.cadlands.com and create an account.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <span>
                                            <i className="fs-4">&bull;</i>
                                        </span>
                                        <p
                                            style={{
                                                marginLeft: "1rem",
                                                textAlign: "left",
                                                fontFamily: "Poppins !important",
                                                fontWeight: "400",
                                                fontSize: "14px",
                                                lineHeight: "24px",
                                                letterSpacing: "0%",
                                            }}
                                        >
                                            If you were referred by someone, input their username as
                                            your referee or register using their referral link.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <span>
                                            <i className="fs-4">&bull;</i>
                                        </span>
                                        <p
                                            style={{
                                                marginLeft: "1rem",
                                                textAlign: "left",
                                                fontFamily: "Poppins !important",
                                                fontWeight: "400",
                                                fontSize: "14px",
                                                lineHeight: "24px",
                                                letterSpacing: "0%",
                                            }}
                                        >
                                            Log in to your already created account and complete your
                                            profile.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <span>
                                            <i className="fs-4">&bull;</i>
                                        </span>
                                        <p
                                            style={{
                                                marginLeft: "1rem",
                                                textAlign: "left",
                                                fontFamily: "Poppins !important",
                                                fontWeight: "400",
                                                fontSize: "14px",
                                                lineHeight: "24px",
                                                letterSpacing: "0%",
                                            }}
                                        >
                                            Proceed to complete your registration by making the
                                            payment, to the company bank account details shown.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <span>
                                            <i className="fs-4">&bull;</i>
                                        </span>
                                        <p
                                            style={{
                                                marginLeft: "1rem",
                                                textAlign: "left",
                                                fontFamily: "Poppins !important",
                                                fontWeight: "400",
                                                fontSize: "14px",
                                                lineHeight: "24px",
                                                letterSpacing: "0%",
                                            }}
                                        >
                                            Send proof of payment through WhatsApp to 090-1300-1300
                                            for confirmation. Share your link widely with your
                                            friends or encourage your friends to sign up under you.
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <a
                            href="#"
                            className="btn btn-white add-listing-btn"
                            style={{
                                marginTop: "0rem",
                                borderRadius: "60px",
                                color: "#fff",
                                backgroundColor: "#510000",
                                padding: "5px 60px",

                            }}
                        >
                            Become an affilate
                        </a>
                    </div>
                </div>
            </div>
        </div></>
    )
}

export default AffiliateProgramSection