import './footer.css'

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <>
      {/* <!--============== Footer Section Start ==============--> */}
      <footer
        className="full-row footer-default-dark bg-footer"
        style={{ paddingBottom: "30px" }}
      >
        <div className="container">
          <div
            className="row row-cols-lg-4 row-cols-md-2 row-cols-1"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div className="col">
              <div className="footer-widget mb-4">
                <div className="footer-logo mb-4">
                  <a href="#">
                    <img
                      src="assets/images/logo/cadland-logo.png"
                      alt="Image not found!"
                    />
                  </a>
                </div>
                <p
                  style={{
                    color: "#000",
                    fontFamily: "Poppins !important",
                    fontWeight: "400",
                    fontSize: "16px !important",
                    lineHeight: "23px",
                    letterSpacing: "1%",
                  }}
                >
                  At Cadlands, we understand that real estate transactions
                  require precision, security, and transparency.
                </p>
              </div>
              <div className="footer-widget media-widget mb-4 mt-5">
                <a href="#">
                  <img
                  className="social-icon"
                    src="assets/images/icon/facebook-icon.png"
                    alt="Image not found!"
                  />
                </a>
                <a href="#">
                  <img
                    className="social-icon"
                    src="assets/images/icon/x-icon.png"
                    alt="Image not found!"
                  />
                </a>
                <a href="#">
                  <img
                    className="social-icon"
                    src="assets/images/icon/instagram-icon.png"
                    alt="Image not found!"
                  />
                </a>
              </div>
            </div>

            <div className="col">
              <div className="footer-widget footer-nav mb-4">
                <h3
                  className="widget-title mb-4"
                  style={{
                    color: "#510000",
                    fontFamily: "Abhaya Libre ExtraBold",
                    fontWeight: "800",
                    fontSize: "18px",
                    lineHeight: "18px",
                    letterSpacing: "1.5px",
                  }}
                >
                  COMPANY
                </h3>
                <ul>
                  <li
                    style={{
                      color: "#510000 !important",
                      fontFamily: "Poppins !important",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "30px",
                      letterSpacing: "0%",
                    }}
                  >
                    <a href="#">About us</a>
                  </li>
                  <li
                    style={{
                      color: "#510000 !important",
                      fontFamily: "Poppins !important",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "30px",
                      letterSpacing: "0%",
                    }}
                  >
                    <a href="#">Services</a>
                  </li>
                  <li
                    style={{
                      color: "#510000 !important",
                      fontFamily: "Poppins !important",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "30px",
                      letterSpacing: "0%",
                    }}
                  >
                    <a href="#">How it works</a>
                  </li>
                  <li
                    style={{
                      color: "#510000 !important",
                      fontFamily: "Poppins !important",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "30px",
                      letterSpacing: "0%",
                    }}
                  >
                    <a href="#">Contact us</a>
                  </li>
                  <li
                    style={{
                      color: "#510000 !important",
                      fontFamily: "Poppins !important",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "30px",
                      letterSpacing: "0%",
                    }}
                  >
                    <a href="#">Testimonials</a>
                  </li>
                  <li
                    style={{
                      color: "#510000 !important",
                      fontFamily: "Poppins !important",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "30px",
                      letterSpacing: "0%",
                    }}
                  >
                    <a href="#">Privacy Policy</a>
                  </li>
                  <li
                    style={{
                      color: "#510000 !important",
                      fontFamily: "Poppins !important",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "30px",
                      letterSpacing: "0%",
                    }}
                  >
                    <a href="#">Privacy Policy</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* <!--============== Footer Section End ==============--> */}

      {/* <!--============== Copyright Section Start ==============--> */}
      <div className="copyright bg-footer text-default py-4">
        <div className="container">
          <div className="row row-cols-md-2 row-cols-1">
            <div className="col">
              <span className="text-white">© 2022 Uniland All right reserved</span>
            </div>
            <div className="col">
              <ul className="line-menu float-end list-color-gray">
                <li>
                  <a href="#">Privacy & Policy </a>
                </li>
                <li>|</li>
                <li>
                  <a href="#">Site Map</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* <!--============== Copyright Section End ==============--> */}

      {/* <!-- Scroll to top --> */}
      <div className="scroll-top-vertical xs-mx-none" id="scroll">
        Go Top <i className="ms-2 fa-solid fa-arrow-right-long"></i>
      </div>
      {/* <!-- End Scroll To top --> */}
    </>
  );
}

export default Footer;
