import './contact-us.css'
function ContactUs() {
    return (
        <div id="contact-us" className="full-row bg-primary" style={{ padding: "0px" }}>
            <div className="">
                <div className="row">
                    <div >
                        <div
                            className="row contact-us-grid"
                        >
                            <div className='contact-us-text'>
                                <p className="text-start contact-us-sub-header-text">
                                    CONTACT US
                                </p>
                                <h2
                                    className="down-line w-100 mx-auto mb-4 text-start w-sm-100 header-text contact-us-header-text"
                                >
                                    Let us handle your <br /> Real Estate investments
                                </h2>

                                <div className="mb-5">
                                    <span
                                        className="d-table w-50 w-sm-100 general-font text-start"
                                        style={{
                                            color: "#fff",
                                            fontFamily: "Poppins",
                                            fontWeight: "800",
                                            fontSize: "16px",
                                            lineHeight: "24px",
                                        }}
                                    >
                                        Call on
                                    </span>
                                    <span
                                        className="d-table w-100 w-sm-100 sub-title general-font text-start"
                                        style={{
                                            color: "#fff",
                                            fontFamily: "Poppins",
                                            fontWeight: "500",
                                            fontSize: "14px",
                                            lineHeight: "24px",
                                        }}
                                    >
                                        Call our team Monday - Sunday, round the clock
                                    </span>
                                    <span
                                        className="d-table w-100 w-sm-100 sub-title general-font text-start"
                                        style={{
                                            color: "#fff",
                                            fontFamily: "Poppins",
                                            fontWeight: "500",
                                            fontSize: "14px",
                                            lineHeight: "24px",
                                        }}
                                    >
                                        +234 912 222 3333, +234 91-2222-6666, +234 912-666-6666
                                    </span>

                                    <span
                                        className="d-table w-50 w-sm-100 sub-title general-font text-start"
                                        style={{
                                            color: "#fff",
                                            fontFamily: "Poppins",
                                            fontWeight: "800",
                                            fontSize: "16px",
                                            lineHeight: "24px",
                                            marginTop: "1rem",
                                        }}
                                    >
                                        Chat with us
                                    </span>
                                    <span
                                        className="d-table w-50 w-sm-100 sub-title general-font text-start"
                                        style={{
                                            color: "#fff",
                                            fontFamily: "Poppins",
                                            fontWeight: "500",
                                            fontSize: "14px",
                                            lineHeight: "24px",
                                        }}
                                    >
                                        Speak to our friendly team
                                    </span>
                                    <span
                                        className="d-table w-100 w-sm-100 sub-title general-font text-start"
                                        style={{
                                            color: "#fff",
                                            fontFamily: "Poppins",
                                            fontWeight: "500",
                                            fontSize: "14px",
                                            lineHeight: "24px",
                                        }}
                                    >
                                        Send us a mail
                                    </span>
                                    <span
                                        className="d-table w-100 w-sm-100 sub-title general-font text-start"
                                        style={{
                                            color: "#fff",
                                            fontFamily: "Poppins",
                                            fontWeight: "500",
                                            fontSize: "14px",
                                            lineHeight: "24px",
                                        }}
                                    >
                                        Message us on X
                                    </span>

                                    <span
                                        className="d-table w-50 w-sm-100 sub-title general-font text-start"
                                        style={{
                                            color: "#fff",
                                            fontFamily: "Poppins",
                                            fontWeight: "800",
                                            fontSize: "18px",
                                            lineHeight: "24px",
                                            marginTop: "1rem",
                                        }}
                                    >
                                        Visit us
                                    </span>
                                    <span
                                        className="d-table w-50 w-sm-100 sub-title general-font text-start"
                                        style={{
                                            color: "#fff",
                                            fontFamily: "Poppins",
                                            fontWeight: "500",
                                            fontSize: "14px",
                                            lineHeight: "24px",
                                        }}
                                    >
                                        Come see us in person at
                                    </span>
                                    <span
                                        className="d-table w-100 w-sm-100 sub-title general-font text-start"
                                        style={{
                                            color: "#fff",
                                            fontFamily: "Poppins",
                                            fontWeight: "500",
                                            fontSize: "14px",
                                            lineHeight: "24px",
                                        }}
                                    >
                                        27A Tunis street, Wuse Zone 6, Abuja
                                    </span>

                                    <span
                                        className="d-table w-50 w-sm-100 sub-title general-font text-start"
                                        style={{
                                            color: "#fff",
                                            fontFamily: "Poppins",
                                            fontWeight: "800",
                                            fontSize: "16px",
                                            lineHeight: "24px",
                                            marginTop: "1rem",
                                        }}
                                    >
                                        Send Us a message
                                    </span>

                                    <div className='contact-us-form'>
                                        <span className="d-table w-50 w-sm-100 sub-title general-font text-start mt-2">
                                            <input
                                                className="form-control"
                                                placeholder="Email Address"
                                                type="text"
                                                disabled
                                            />
                                        </span>

                                        <span
                                            className="d-table w-50 w-sm-100 sub-title general-font text-start mt-2"
                                        >
                                            <textarea
                                                className="form-control"
                                                placeholder="Type Message"
                                                disabled
                                            ></textarea>
                                        </span>
                                        <span
                                            className="d-table w-50 w-sm-100 sub-title general-font text-start mt-2"
                                        >
                                            <a
                                                className="btn btn-white add-listing-btn w-100"
                                                style={{
                                                    borderRadius: "60px",
                                                    border: "1px solid #510000",
                                                }}
                                            >
                                                Send Us a Message
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div

                                style={{
                                    paddingRight: "288.55px",
                                    paddingLeft: "0px",
                                    backgroundColor: "#B4C4C3",
                                }}
                            >
                                <img
                                    src="assets/images/testimonials/contct-us-img-2.png"
                                    alt="Image Not Found!"
                                    style={{ float: "right", width: "817px !important", height: "100%" }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row"></div>
            </div>
        </div>
    )
}

export default ContactUs