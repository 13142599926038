import './affiliate-program.css'

function AffiliateProgram() {
  return (
    <>
      <div className="full-row marginTop">
        <div className="container">
          <div className="row affilate-program-section">
            <div className="col">
              <img
                src="assets/images/affiliate-program/affiliate-program-img.png"
                alt="Image Not Found!"
              />
            </div>
            <div className="col">
              <div className="text-secondary text-center mb-5">
                <h2
                  className="text-secondary text-start mb-4 header-text"
                >
                  Welcome to Cadlands Affiliate Program
                </h2>

                <h2
                  className="text-black text-start mb-1"
                  style={{
                    fontFamily: "Poppins !important",
                    fontWeight: "500",
                    fontSize: "24px",
                    lineHeight: "35px",
                    marginTop: "1rem",
                  }}
                >
                  About the program
                </h2>
                <span
                  className="d-table sub-title text-start"
                  style={{
                    fontFamily: "Poppins !important",
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "30px",
                    letterSpacing: "0%",
                  }}
                >
                  Welcome to CADLANDS Affiliate Program, a robust system
                  designed to help our affiliates earn more from real estate. We
                  are a real estate firm with the global stage in mind.
                </span>
                <span
                  className="d-table sub-title text-start"
                  style={{
                    fontFamily: "Poppins !important",
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "30px",
                    letterSpacing: "0%",
                  }}
                >
                  Would you love to join us and be a part of that dream? We have
                  developing estates for sale, where you make up to 5-15%
                  commission per sales when you refer someone to buy from us.
                </span>
                <span
                  className="d-table sub-title text-start"
                  style={{
                    fontFamily: "Poppins !important",
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "30px",
                    letterSpacing: "0%",
                  }}
                >
                  For instance, if you refer a client who purchases a plot of
                  land worth 6.5 million from us, this earns you a whopping
                  N650,000 from a single sale
                </span>

                <h2
                  className="text-black text-start mb-1"
                  style={{
                    fontFamily: "Poppins !important",
                    fontWeight: "500",
                    fontSize: "24px",
                    lineHeight: "35px",
                    marginTop: "1rem",
                  }}
                >
                  How it works
                </h2>
                <ul
                  style={{
                    listStyleType: "disc !important",
                    paddingLeft: "15px",
                  }}
                >
                  <li>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <span>
                        <i className="fs-4">&bull;</i>
                      </span>
                      <p
                        style={{
                          marginLeft: "1rem",
                          textAlign: "left",
                          fontFamily: "Poppins !important",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "30px",
                          letterSpacing: "0%",
                        }}
                      >
                        Visit ca.cadlands.com and create an account.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <span>
                        <i className="fs-4">&bull;</i>
                      </span>
                      <p
                        style={{
                          marginLeft: "1rem",
                          textAlign: "left",
                          fontFamily: "Poppins !important",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "30px",
                          letterSpacing: "0%",
                        }}
                      >
                        If you were referred by someone, input their username as
                        your referee or register using their referral link.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <span>
                        <i className="fs-4">&bull;</i>
                      </span>
                      <p
                        style={{
                          marginLeft: "1rem",
                          textAlign: "left",
                          fontFamily: "Poppins !important",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "30px",
                          letterSpacing: "0%",
                        }}
                      >
                        Log in to your already created account and complete your
                        profile.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <span>
                        <i className="fs-4">&bull;</i>
                      </span>
                      <p
                        style={{
                          marginLeft: "1rem",
                          textAlign: "left",
                          fontFamily: "Poppins !important",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "30px",
                          letterSpacing: "0%",
                        }}
                      >
                        Proceed to complete your registration by making the
                        payment, to the company bank account details shown.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <span>
                        <i className="fs-4">&bull;</i>
                      </span>
                      <p
                        style={{
                          marginLeft: "1rem",
                          textAlign: "left",
                          fontFamily: "Poppins !important",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "30px",
                          letterSpacing: "0%",
                        }}
                      >
                        Send proof of payment through WhatsApp to 090-1300-1300
                        for confirmation. Share your link widely with your
                        friends or encourage your friends to sign up under you.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <a
                href="https://ca.cadlands.com/account/affiliate-registration"
                target="_blank"
                className="btn btn-white add-listing-btn"
                style={{
                  marginTop: "0rem",
                  borderRadius: "60px",
                  color: "#fff",
                  backgroundColor: "#510000",
                  padding: "5px 60px",
                }}
              >
                Signup
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="full-row" style={{ backgroundColor: "#FAFAFA" }}>
        <div className="container">
          <div className="row">
            <div className="col mb-5">
              <h2
                className="down-line w-100 mx-auto mb-4 text-center w-sm-100 about-our-affilate-program-header-text"
              >
                All your need to know about our affiliate Program
              </h2>
            </div>
          </div>
          <div className="row row-cols-lg-5 row-cols-sm-4 row-cols-1 g-3 justify-content-center">
            <div className="col">
              <a className="text-left d-flex flex-column hover-text-dark p-1 transation text-general h-100">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "1rem",
                  }}
                >
                  <div className="position-relative">
                    <img
                      src="assets/images/affiliate-program/icon-1.png"
                      style={{ width: 'auto', height: 'auto' }}
                      alt="Image Not Found!"
                    />
                  </div>
                  <h6
                    className="d-block text-secondary"
                    style={{
                      fontFamily: "Poppins !important",
                      fontWeight: "700",
                      fontSize: "18px",
                      lineHeight: "36.4px",
                      letterSpacing: "-1%",
                      textAlign: "left",
                      margin: "0rem 0rem",
                    }}
                  >
                    HOW DO YOU EARN FROM REAL ESTATE?
                  </h6>
                </div>
                <p
                  style={{
                    color: "#000",
                    fontSize: "14px",
                    fontFamily: "Poppins !important",
                    fontWeight: "400",
                    lineHeight: "24px",
                  }}
                >
                  With us, there are two major ways; you can be an investment
                  partner or an affiliate partner.
                  <div style={{}}>
                    <ul
                      style={{
                        listStyleType: "disc !important",
                        paddingLeft:
                          "15px",
                      }}
                    >
                      <li
                        style={{
                          marginTop: "0.5rem",
                        }}
                      >
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <span>
                            <i className="fs-4">&bull;</i>
                          </span>
                          <p style={{ marginLeft: "1rem" }}>
                            As an investment partner, we help you purchase
                            properties with great prospects for estate
                            development, land banking, flipping etc. Being an
                            Investment Partner is quite capital intensive.
                          </p>
                        </div>
                      </li>
                      <li
                        style={{
                          marginTop: "0.5rem",
                        }}
                      >
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <span>
                            <i className="fs-4">&bull;</i>
                          </span>
                          <p style={{ marginLeft: "1rem" }}>
                            On the other hand, being an affiliate partner
                            requires almost zero capital. Affiliate Partnership
                            requires dedicated efforts in marketing. It is a
                            performance-based and rewards are in commissions
                            determined by the affiliate's own marketing efforts.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </p>
              </a>
            </div>

            <div className="col">
              <a className="text-left d-flex flex-column hover-text-dark p-1 transation text-general h-100">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "1rem",
                  }}
                >
                  <div className="position-relative">
                    <img
                      src="assets/images/affiliate-program/icon-1.png"
                      style={{ width: 'auto', height: 'auto' }}
                      alt="Image Not Found!"
                    />
                  </div>
                  <h6
                    className="d-block text-secondary"
                    style={{
                      fontFamily: "Poppins !important",
                      fontWeight: "700",
                      fontSize: "18px",
                      lineHeight: "36.4px",
                      letterSpacing: "-1%",
                      textAlign: "left",
                      margin: "0rem 0rem",
                    }}
                  >
                    HOW DO I EARN FROM SALES
                  </h6>
                </div>
                <p
                  style={{
                    color: "#000",
                    fontSize: "14px",
                    fontFamily: "Poppins !important",
                    fontWeight: "400",
                    lineHeight: "24px",
                  }}
                >
                  <ul
                    style={{
                      listStyleType: "disc !important",
                      paddingLeft:
                        "15px",
                    }}
                  >
                    <li
                      style={{
                        marginTop: "0.5rem",
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <span>
                          <i className="fs-4">&bull;</i>
                        </span>
                        <p style={{ marginLeft: "1rem" }}>
                          For every sales done through you, your balance gets
                          credited with your mandatory %commission after payment
                          confirmation.
                        </p>
                      </div>
                    </li>
                    <li
                      style={{
                        marginTop: "0.5rem",
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <span>
                          <i className="fs-4">&bull;</i>
                        </span>
                        <p style={{ marginLeft: "1rem" }}>
                          For every client who buys land through someone
                          registered under you (your direct down-line), your
                          balance gets credited with a 5% commission.
                        </p>
                      </div>
                    </li>
                  </ul>
                </p>
              </a>
            </div>

            <div className="col">
              <a className="text-left d-flex flex-column hover-text-dark p-1 transation text-general h-100">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "1rem",
                  }}
                >
                  <div className="position-relative">
                    <img
                      src="assets/images/affiliate-program/icon-1.png"
                      style={{ width: 'auto', height: 'auto' }}
                      alt="Image Not Found!"
                    />
                  </div>
                  <h6
                    className="d-block text-secondary"
                    style={{
                      fontFamily: "Poppins !important",
                      fontWeight: "700",
                      fontSize: "18px",
                      lineHeight: "36.4px",
                      letterSpacing: "-1%",
                      textAlign: "left",
                      margin: "0rem 0rem",
                    }}
                  >
                    HOW DO I EARN ON REFERAL
                  </h6>
                </div>
                <p
                  style={{
                    color: "#000",
                    fontSize: "14px",
                    fontFamily: "Poppins !important",
                    fontWeight: "400",
                    lineHeight: "24px",
                  }}
                >
                  <ul
                    style={{
                      listStyleType: "disc !important",
                      paddingLeft:
                        "15px"
                    }}
                  >
                    <li
                      style={{
                        marginTop: "0.5rem",
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <span>
                          <i className="fs-4">&bull;</i>
                        </span>
                        <p style={{ marginLeft: "1rem" }}>
                          For every person who signs up under you and confirms
                          payment, your balance gets instantly credited with 50%
                          of their registration fee which you can withdraw
                          anytime.
                        </p>
                      </div>
                    </li>
                    <li
                      style={{
                        marginTop: "0.5rem",
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <span>
                          <i className="fs-4">&bull;</i>
                        </span>
                        <p style={{ marginLeft: "1rem" }}>
                          If someone registers under your direct down line as
                          well, and eventually makes a sale; your direct down
                          line would receive a 5% commission while you would
                          receive a 3% commission.
                        </p>
                      </div>
                    </li>
                    <li
                      style={{
                        marginTop: "0.5rem",
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <span>
                          <i className="fs-4">&bull;</i>
                        </span>
                        <p style={{ marginLeft: "1rem" }}>
                          You can always withdraw any or all of your balance at
                          any time.
                        </p>
                      </div>
                    </li>
                  </ul>
                </p>
              </a>
            </div>
          </div>

          <div className="row row-cols-lg-4 row-cols-sm-4 row-cols-1 g-3 justify-content-center mt-5">
            <div className="col">
              <a className="text-left d-flex flex-column hover-text-dark p-1 transation text-general h-100">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "1rem",
                  }}
                >
                  <div className="position-relative">
                    <img
                      src="assets/images/affiliate-program/icon-1.png"
                      style={{ width: 'auto', height: 'auto' }}
                      alt="Image Not Found!"
                    />
                  </div>
                  <h6
                    className="d-block text-secondary"
                    style={{
                      fontFamily: "Poppins !important",
                      fontWeight: "700",
                      fontSize: "18px",
                      lineHeight: "36.4px",
                      letterSpacing: "-1%",
                      textAlign: "left",
                      margin: "0rem 0rem",
                    }}
                  >
                    WHAT DO I STAND TO GAIN?
                  </h6>
                </div>
                <p
                  style={{
                    color: "#000",
                    fontSize: "14px",
                    fontFamily: "Poppins !important",
                    fontWeight: "400",
                    lineHeight: "24px",
                  }}
                >
                  <ul
                    style={{
                      listStyleType: "disc !important",
                      paddingLeft:
                        "15px"
                    }}
                  >
                    <li
                      style={{
                        marginTop: "0.5rem",
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <span>
                          <i className="fs-4">&bull;</i>
                        </span>
                        <p style={{ marginLeft: "1rem" }}>
                          5% direct commission on sales for unregistered
                          affiliates and 15% for Registered affiliates.
                        </p>
                      </div>
                    </li>
                    <li
                      style={{
                        marginTop: "0.5rem",
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <span>
                          <i className="fs-4">&bull;</i>
                        </span>
                        <p style={{ marginLeft: "1rem" }}>
                          2 levels of indirect commission (5% and 3%).
                        </p>
                      </div>
                    </li>
                    <li
                      style={{
                        marginTop: "0.5rem",
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <span>
                          <i className="fs-4">&bull;</i>
                        </span>
                        <p style={{ marginLeft: "1rem" }}>
                          15% referral bonus on every sign-up fee.
                        </p>
                      </div>
                    </li>
                    <li
                      style={{
                        marginTop: "0.5rem",
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <span>
                          <i className="fs-4">&bull;</i>
                        </span>
                        <p style={{ marginLeft: "1rem" }}>
                          Access to materials on real estate marketing.
                        </p>
                      </div>
                    </li>
                    <li
                      style={{
                        marginTop: "0.5rem",
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <span>
                          <i className="fs-4">&bull;</i>
                        </span>
                        <p style={{ marginLeft: "1rem" }}>
                          Eligible for promo offers and incentives.
                        </p>
                      </div>
                    </li>
                  </ul>
                </p>
              </a>
            </div>

            <div className="col">
              <a className="text-left d-flex flex-column hover-text-dark p-1 transation text-general h-100">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "1rem",
                  }}
                >
                  <div className="position-relative">
                    <img
                      src="assets/images/affiliate-program/icon-1.png"
                      style={{ width: 'auto', height: 'auto' }}
                      alt="Image Not Found!"
                    />
                  </div>
                  <h6
                    className="d-block text-secondary"
                    style={{
                      fontFamily: "Poppins !important",
                      fontWeight: "700",
                      fontSize: "18px",
                      lineHeight: "36.4px",
                      letterSpacing: "-1%",
                      textAlign: "left",
                      margin: "0rem 0rem",
                    }}
                  >
                    WHAT DOES THE REGISTERATION FEE COVER?
                  </h6>
                </div>
                <p
                  style={{
                    color: "#000",
                    fontSize: "14px",
                    fontFamily: "Poppins !important",
                    fontWeight: "400",
                    lineHeight: "24px",
                  }}
                >
                  The registration fee covers the cost of The Real Estate
                  handbook, maintaining your account and being a part of the
                  program. Your direct up line would also receive 15% of your
                  registration fee as a referral bonus.
                </p>
              </a>
            </div>

            <div className="col">
              <a className="text-left d-flex flex-column hover-text-dark p-1 transation text-general h-100">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "1rem",
                  }}
                >
                  <div className="position-relative">
                    <img
                      src="assets/images/affiliate-program/icon-1.png"
                      style={{ width: 'auto', height: 'auto' }}
                      alt="Image Not Found!"
                    />
                  </div>
                  <h6
                    className="d-block text-secondary"
                    style={{
                      fontFamily: "Poppins !important",
                      fontWeight: "700",
                      fontSize: "18px",
                      lineHeight: "36.4px",
                      letterSpacing: "-1%",
                      textAlign: "left",
                      margin: "0rem 0rem",
                    }}
                  >
                    MUST I SIGN UP UNDER SOMEONE?
                  </h6>
                </div>
                <p
                  style={{
                    color: "#000",
                    fontSize: "14px",
                    fontFamily: "Poppins !important",
                    fontWeight: "400",
                    lineHeight: "24px",
                  }}
                >
                  No. The system accepts direct sign-ups too. But if you were
                  referred or invited by someone, you should sign up under them.
                </p>
              </a>
            </div>

            <div className="col">
              <a className="text-left d-flex flex-column hover-text-dark p-1 transation text-general h-100">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "1rem",
                  }}
                >
                  <div className="position-relative">
                    <img
                      src="assets/images/affiliate-program/icon-1.png"
                      style={{ width: 'auto', height: 'auto' }}
                      alt="Image Not Found!"
                    />
                  </div>
                  <h6
                    className="d-block text-secondary"
                    style={{
                      fontFamily: "Poppins !important",
                      fontWeight: "700",
                      fontSize: "18px",
                      lineHeight: "36.4px",
                      letterSpacing: "-1%",
                      textAlign: "left",
                      margin: "0rem 0rem",
                    }}
                  >
                    IS THE PAYMENT COMPULSORY?
                  </h6>
                </div>
                <p
                  style={{
                    color: "#000",
                    fontSize: "14px",
                    fontFamily: "Poppins !important",
                    fontWeight: "400",
                    lineHeight: "24px",
                  }}
                >
                  No. The affiliate registration fee is only compulsory for
                  affiliates who intend to earn the 5% and 3% indirect
                  commissions as well as promos. As a non-registered affiliate,
                  you are ONLY entitled to your 5% direct commission. But to
                  enjoy the two levels of indirect sales commission (5% and 3%),
                  you have to pay the affiliate registration fee.
                </p>
              </a>
            </div>
          </div>

          <div className="row justify-content-center">
            <div
              className="col-lg-10 get-in-touch-container"
            >
              <div
                className='get-in-touch'
              >
                <div
                  className='get-in-touch-text-container'
                >
                  <p
                    style={{
                      fontFamily: "Poppins !important",
                      fontWeight: "700",
                      fontSize: "18px",
                      lineHeight: "100%",
                      letterSpacing: "0%",
                      color: "#fff",
                    }}
                  >
                    Still have questions?
                  </p>
                  <p
                    className='get-in-touch-text'
                  >
                    You can always reach out to us via any of our social media
                    handles @cadlandsgroup. You can also reach out to us via
                    call/WhatsApp - 090-1300-1300
                  </p>
                </div>

                <div className='get-in-touch-button'>
                  <a
                    href="affiliate-program"
                    className="btn btn-white add-listing-btn"
                    style={{
                      marginLeft: "1rem",
                      borderRadius: "60px",
                      border: "1px solid #510000",
                      color: "#510000",
                    }}
                  >
                    Get in touch
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AffiliateProgram;