function Banner() {
    return (
        <>
            {/* <div
                className="full-row p-0 position-relative"
                style={{
                    backgroundImage: "url('assets/images/background/cadland-bg-img.png')",
                    backgroundPosition: "center center",
                    backgroundSize: "cover",
                    height: "90vh",
                    display: "flex",
                    alignItems: "flex-end",
                    position: "relative",
                }}
            >
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        zIndex: 1,
                    }}
                ></div>

                <div className="container" style={{ position: "relative", zIndex: 2 }}>
                    <div className="row py-8">
                        <div className="col-md-10">
                            <div
                                className="banner-search sm-p-0"
                                style={{ paddingTop: "170px", paddingBottom: "10px" }}
                            >
                                <h2
                                    className="mx-auto text-white"
                                    style={{
                                        fontSize: "80px",
                                        fontWeight: "800",
                                        lineHeight: "90px",
                                        textAlign: "start",
                                    }}
                                >
                                    Your Trusted Partner in Real Estate Acquisition
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div
                className="full-row p-0 position-relative vh-100 d-flex align-items-end"
                style={{
                    backgroundImage: "url('assets/images/background/cadland-bg-img.png')",
                    backgroundPosition: "center center",
                    backgroundSize: "cover",
                    position: "relative",
                }}
            >
                {/* Overlay */}
                <div
                    className="position-absolute top-0 start-0 w-100 h-100"
                    style={{
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        zIndex: 1,
                    }}
                ></div>

                <div
                    className="container position-relative"
                    style={{ zIndex: 2 }}
                >
                    <div className="row py-4 py-md-8">
                        <div className="col-12 col-md-10">
                            <div
                                className="banner-search sm-p-0 pt-5 pb-3"
                            >
                                <h2
                                    className="text-white"
                                    style={{
                                        fontSize: "clamp(36px, 8vw, 80px)", // Responsive font size
                                        fontWeight: "800",
                                        lineHeight: "clamp(1.2, 1.125, 1.125)", // Responsive line height
                                        textAlign: "start",
                                    }}
                                >
                                    Your Trusted Partner in Real Estate Acquisition
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="full-row" style={{ paddingTop: "2rem" }}>
                <div className="container">
                    <div className="row">
                        {/* <div className="col mb-5">
                            <span
                                className="d-table w-70 w-sm-100 general-font fw-normal text-start"
                                style={{
                                    fontFamily: "Poppins",
                                    fontWeight: "400",
                                    fontSize: "16px",
                                    lineHeight: "30px",
                                    letterSpacing: "0%",
                                    color: "#000",
                                }}
                            >
                                At CADLANDS, we believe that every piece of property represents the potential for a brighter tomorrow With integrity, innovation, sustainability, and customer-centricity at our core, we’re redefining what it means to invest in real estate in Nigeria.
                            </span>
                            <span
                                className="d-table w-70 w-sm-100 mt-1 sub-title general-font fw-normal text-start"
                                style={{
                                    fontFamily: "Poppins",
                                    fontWeight: "400",
                                    fontSize: "16px",
                                    lineHeight: "23px",
                                    letterSpacing: "0%",
                                    color: "#000",
                                }}
                            >
                                More than just buying and selling properties, we are building communities, fostering sustainable development, and creating lasting value. From conception to completion, our projects are meticulously designed to blend modern infrastructure with community-centric living, ensuring that each development is as resilient as it is innovative.
                            </span>
                            <a
                                href="#"
                                className="btn btn-white add-listing-btn"
                                style={{
                                    marginTop: "1rem",
                                    borderRadius: "60px",
                                    color: "#fff",
                                    backgroundColor: "#510000",
                                    padding: "5px 60px",
                                }}
                            >
                                Buy Now
                            </a>
                        </div> */}
                        <div className="col mb-5">
                            <span
                                className="d-block w-100 w-md-75 w-lg-70 general-font text-start"
                                style={{
                                    fontFamily: "Poppins",
                                    fontWeight: "400",
                                    fontSize: "clamp(12px, 4vw, 16px)", // Responsive font size
                                    lineHeight: "1.875", // Converted from 30px/16px
                                    color: "#000",
                                }}
                            >
                                At CADLANDS, we believe that every piece of property represents the potential for a brighter tomorrow. With integrity, innovation, sustainability, and customer-centricity at our core, we're redefining what it means to invest in real estate in Nigeria.
                            </span>
                            <span
                                className="d-block w-100 w-md-75 w-lg-70 mt-2 sub-title general-font text-start"
                                style={{
                                    fontFamily: "Poppins",
                                    fontWeight: "400",
                                    fontSize: "clamp(12px, 3.8vw, 16px)", // Slightly smaller, responsive font size
                                    lineHeight: "1.875", // Converted from 30px/16px
                                    color: "#000",
                                }}
                            >
                                More than just buying and selling properties, we are building communities, fostering sustainable development, and creating lasting value. From conception to completion, our projects are meticulously designed to blend modern infrastructure with community-centric living, ensuring that each development is as resilient as it is innovative.
                            </span>
                            <a
                                href="#"
                                className="btn btn-white add-listing-btn mt-3 d-inline-block"
                                style={{
                                    borderRadius: "60px",
                                    color: "#fff",
                                    backgroundColor: "#510000",
                                    padding: "0.5rem 2rem", // Converted from fixed pixels to rem
                                    fontSize: "clamp(14px, 4vw, 16px)", // Responsive button text
                                    textAlign: "center",
                                }}
                            >
                                Buy Now
                            </a>
                        </div>
                    </div>

                    {/* <div className="row row-cols-lg-4 row-cols-sm-4 row-cols-1 g-9 justify-content-center">
                        <div
                            className="col"
                            style={{
                                boxShadow:
                                    "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
                            }}
                        >
                            <a
                                href="#"
                                className="text-center d-flex flex-column align-items-center p-4 bg-white transation text-general h-70"
                            >
                                <h1
                                    className="d-block text-secondary"
                                    style={{
                                        fontFamily: "Poppins !important",
                                        fontWeight: "700",
                                        fontSize: "38px",
                                        lineHeight: "49.4px",
                                        letterSpacing: "-2%",
                                        textAlign: "center",
                                        color: "#000",
                                    }}
                                >
                                    10k+
                                </h1>
                                <p
                                    style={{
                                        fontFamily: "Mulish !important",
                                        fontWeight: "400",
                                        fontSize: "16px",
                                        lineHeight: "20.8px",
                                        textAlign: "center",
                                        color: "#000",
                                    }}
                                >
                                    Properties Sold
                                </p>
                            </a>
                        </div>

                        <div
                            className="col"
                            style={{
                                boxShadow:
                                    "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
                            }}
                        >
                            <a
                                href="#"
                                className="text-center d-flex flex-column align-items-center p-4 bg-white transation text-general h-70"
                            >
                                <h1
                                    className="d-block text-secondary"
                                    style={{
                                        fontFamily: "Poppins !important",
                                        fontWeight: "700",
                                        fontSize: "38px",
                                        lineHeight: "49.4px",
                                        letterSpacing: "-2%",
                                        textAlign: "center",
                                        color: "#000",
                                    }}
                                >
                                    931k+
                                </h1>
                                <p
                                    style={{
                                        fontFamily: "Mulish !important",
                                        fontWeight: "400",
                                        fontSize: "16px",
                                        lineHeight: "20.8px",
                                        textAlign: "center",
                                        color: "#000",
                                    }}
                                >
                                    Trusted Clients
                                </p>
                            </a>
                        </div>
                        <div
                            className="col"
                            style={{
                                boxShadow:
                                    "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
                            }}
                        >
                            <a
                                href="#"
                                className="text-center d-flex flex-column align-items-center p-4 bg-white transation text-general h-70"
                            >
                                <h1
                                    className="d-block text-secondary"
                                    style={{
                                        fontFamily: "Poppins !important",
                                        fontWeight: "700",
                                        fontSize: "38px",
                                        lineHeight: "49.4px",
                                        letterSpacing: "-2%",
                                        textAlign: "center",
                                        color: "#000",
                                    }}
                                >
                                    3+
                                </h1>
                                <p
                                    style={{
                                        fontFamily: "Mulish !important",
                                        fontWeight: "400",
                                        fontSize: "16px",
                                        lineHeight: "20.8px",
                                        textAlign: "center",
                                        color: "#000",
                                    }}
                                >
                                    Our Estates
                                </p>
                            </a>
                        </div>
                        <div
                            className="col"
                            style={{
                                boxShadow:
                                    "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
                            }}
                        >
                            <a
                                href="#"
                                className="text-center d-flex flex-column align-items-center p-4 bg-white transation text-general h-70"
                            >
                                <h1
                                    className="d-block text-secondary"
                                    style={{
                                        fontFamily: "Poppins !important",
                                        fontWeight: "700",
                                        fontSize: "38px",
                                        lineHeight: "49.4px",
                                        letterSpacing: "-2%",
                                        textAlign: "center",
                                        color: "#000",
                                    }}
                                >
                                    12k+
                                </h1>
                                <p
                                    style={{
                                        fontFamily: "Mulish !important",
                                        fontWeight: "400",
                                        fontSize: "16px",
                                        lineHeight: "20.8px",
                                        textAlign: "center",
                                        color: "#000",
                                    }}
                                >
                                    Choice Locations
                                </p>
                            </a>
                        </div>
                    </div> */}
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-4 g-4 g-lg-9 justify-content-center">
                        {[
                            { number: "10k+", label: "Properties Sold" },
                            { number: "931k+", label: "Trusted Clients" },
                            { number: "3+", label: "Our Estates" },
                            { number: "12k+", label: "Choice Locations" }
                        ].map((stat, index) => (
                            <div
                                key={index}
                                className="col"
                                style={{
                                    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
                                }}
                            >
                                <a
                                    href="#"
                                    className="text-center d-flex flex-column align-items-center p-3 p-md-4 bg-white transition text-general h-100"
                                >
                                    <h1
                                        className="d-block text-secondary mb-2"
                                        style={{
                                            fontFamily: "Poppins, sans-serif",
                                            fontWeight: "700",
                                            fontSize: "clamp(28px, 8vw, 38px)", // Responsive font size
                                            lineHeight: "1.3", // Relative line height
                                            letterSpacing: "-0.02em",
                                            textAlign: "center",
                                            color: "#000",
                                        }}
                                    >
                                        {stat.number}
                                    </h1>
                                    <p
                                        style={{
                                            fontFamily: "Mulish, sans-serif",
                                            fontWeight: "400",
                                            fontSize: "clamp(14px, 4vw, 16px)", // Responsive font size
                                            lineHeight: "1.3", // Relative line height
                                            textAlign: "center",
                                            color: "#000",
                                        }}
                                    >
                                        {stat.label}
                                    </p>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Banner