import React from 'react';
// import './App.css';
import { Route, Routes } from 'react-router-dom';
import { publicRoutes } from './routes';
import NonAuthLayout from './components/NonAuthLayout';
import { QueryClient, QueryClientProvider } from 'react-query';

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {},
  })
  return (
    <React.Fragment>
      <QueryClientProvider client={queryClient}>
        <Routes>
          {publicRoutes.map((route, idx) => (
            <Route
              path={route.path}
              // element={<NonAuthLayout>{route.component}</NonAuthLayout>}
              element={<NonAuthLayout><route.component /></NonAuthLayout>}
              key={idx}
            // exact={true}
            />
          ))}
        </Routes>
      </QueryClientProvider>
    </React.Fragment>
  );
}

export default App;
