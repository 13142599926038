// Import all components
import Home from "../pages/Home";
import ApplicationForm from "../pages/ApplicationForm";
import PropertyDetail from "../pages/PropertyDetail";
import EstateDetail from "../pages/EstateDetail";
import AffiliateProgram from "../pages/AffiliateProgram";
import ApplicationFormEstate from "../pages/ApplicationFormEstate";
import ApplicationFormGeneral from "../pages/ApplicationFormGeneral";

const publicRoutes = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/buy-land",
    // path: "/buy-land",
    component: ApplicationFormGeneral,
  },
  {
    path: "/buy-land/:template",
    // path: "/buy-land",
    component: ApplicationForm,
  },
  {
    path: "/buy-estate-land/:estate",
    component: ApplicationFormEstate,
  },
  {
    path: "/property-detail",
    component: PropertyDetail,
  },
  {
    path: "/estates/:id",
    component: EstateDetail,
  },
  {
    path: "/affiliate-program",
    component: AffiliateProgram,
  },
];

export { publicRoutes };