import React from "react";

function PropertyDetail() {
  return (
    <>
      {/* <!--============== Page title Start ==============--> */}
      <div className="full-row py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav aria-label="breadcrumb" className="mb-2">
                <ol className="breadcrumb mb-0 bg-transparent p-0">
                  <li className="breadcrumb-item">
                    <a href="index.html">Home</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#">Property</a>
                  </li>
                  <li
                    className="breadcrumb-item active text-primary"
                    aria-current="page"
                  >
                    Single Property
                  </li>
                </ol>
              </nav>
              <h3 className="text-secondary">Single Property</h3>
            </div>
          </div>
        </div>
      </div>
      {/* <!--============== Page title End ==============--> */}

      <div className="full-row pt-0">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 order-xl-1">
              <img src="./assets/images/property/property-map.png" />

              <div className="mt-5">
                <h3
                  className="text-secondary"
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "24px",
                    fontWeight: "700",
                    lineHeight: "16px",
                  }}
                >
                  Precautionary Tip
                </h3>
                <p
                  style={{
                    fontFamily: "Poppins !important",
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "24px",
                    letterSpacing: "0%",
                    marginBottom: "10px",
                  }}
                >
                  <span className="mr-5">1.</span>Do not make any inspection fee
                  without seeing the agent and property.
                </p>
                <p
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "24px",
                    letterSpacing: "0%",
                    marginBottom: "10px",
                  }}
                >
                  <span className="mr-5">2.</span>Only pay Rental fee, Sales fee
                  or any upfront payment after you verify the Landlord.
                </p>
                <p
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "24px",
                    letterSpacing: "0%",
                    marginBottom: "10px",
                  }}
                >
                  <span className="mr-5">3.</span>Ensure you meet the Agent in
                  an open location.
                </p>
                <p
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "24px",
                    letterSpacing: "0%",
                    marginBottom: "10px",
                  }}
                >
                  <span className="mr-5">4.</span>The Agent does not represent
                  Landbank and Landbank is not liable for any monetary
                  transaction between you and the Agent.
                </p>
              </div>
            </div>

            <div className="col-xl-6 order-xl-2">
              <div className="property-overview border summary rounded bg-white p-20 mb-50">
                <div className="row">
                  <div className="col-md-12">
                    <div
                      id="single-property"
                      style={{
                        margin: "0 auto 30px",
                      }}
                    >
                      {/* <!-- Slide 1--> */}
                      {/* className="ls-slide" */}
                      <div data-ls="duration:7500; transition2d:5; kenburnszoom:in; kenburnsscale:1.2;">
                        {" "}
                        <img
                          width="800"
                          height="500"
                          src="./assets/images/slider/5.png"
                          className="ls-bg"
                          alt=""
                        />{" "}
                      </div>

                      {/* <!-- Slide 2--> */}
                      <div
                        className="ls-slide"
                        data-ls="duration:7500; transition2d:5; kenburnszoom:out; kenburnsscale:1.2;"
                      >
                        {" "}
                        <img
                          width="1920"
                          height="1280"
                          src="./assets/images/slider/6.png"
                          className="ls-bg"
                          alt=""
                        />{" "}
                      </div>

                      {/* <!-- Slide 3--> */}
                      <div
                        className="ls-slide"
                        data-ls="duration:7500; transition2d:5; kenburnszoom:in; kenburnsscale:1.2;"
                      >
                        {" "}
                        <img
                          width="1920"
                          height="1280"
                          src="./assets/images/slider/8.png"
                          className="ls-bg"
                          alt=""
                        />{" "}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-auto">
                    <h4
                      className="listing-title"
                      style={{
                        fontFamily: "Inter !important",
                        fontSize: "24px !important",
                        fontWeight: "600 !important",
                        lineHeight: "29px !important",
                      }}
                    >
                      <a href="property-single-v1.html">
                        Apolo Family House Residense
                      </a>
                    </h4>
                    {/* <span className="listing-location">
                      <i className="fas fa-map-marker-alt text-primary"></i> 2305
                      Tree Lane Overlandpk, MO 66210
                    </span> */}
                  </div>
                </div>
                <div className="row row-cols-1">
                  <div className="col">
                    {/* <h5 className="mb-3">Description</h5> */}
                    <p
                      style={{
                        fontFamily: "Poppins !important",
                        fontSize: "16px",
                        fontWeight: "400",
                        lineHeight: "24px",
                        color: "#000",
                      }}
                    >
                      Maecenas egestas quam et volutpat bibendum metus vulputate
                      platea eleifend sed Integer dictum ultricies consectetuer
                      nunc vivamus a. Eu mus justo magna lacinia purus sodales
                      scelerisque. Sociosqu pede facilisi. Curae; lacinia id.
                    </p>
                  </div>
                </div>

                <div className="col-12">
                  <hr />
                  <div className="product-offers mt-2">
                    <ul className="product-offers-list">
                      <li className="product-offer-item text-black">
                        {" "}
                        4 Bathrooms en suite
                      </li>
                      <li className="product-offer-item text-black">
                        {" "}
                        Spacious BQ and gateman house
                      </li>
                      <li className="product-offer-item text-black">
                        {" "}
                        6 toilets including guest toilet for sitting rooms
                      </li>
                      <li className="product-offer-item text-black"> 465 SQM</li>
                      <li className="product-offer-item text-black">
                        {" "}
                        12 car parking space
                      </li>
                      <li className="product-offer-item text-black">
                        {" "}
                        Asking Price:{" "}
                        <strong>NGN400,000,000 ( Slightly Negotiable)</strong>
                      </li>
                    </ul>
                  </div>
                </div>

                <ul className="quick-meta mt-4">
                  <li className="w-auto">
                    <a
                      href="#"
                      className="btn btn-white text-primary"
                      style={{
                        marginLeft: "1rem",
                        borderRadius: "60px",
                        border: "1px solid #510000",
                      }}
                    >
                      Buy Now
                    </a>
                  </li>

                  <li className="w-auto">
                    <a
                      href="#"
                      className="btn btn-white text-primary"
                      style={{
                        marginLeft: "1rem",
                        borderRadius: "60px",
                        border: "1px solid #510000",
                      }}
                    >
                      Share
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PropertyDetail;
