import './our-core-values.css'
function OurCoreValues() {
    return (
        <><div className="full-row">
            <div className="container">
                <div className="row">
                    <div className="col mb-5">
                        <p
                            className="text-center"
                            style={{
                                color: "#0A0A0A",
                                fontFamily: "Abhaya Libre ExtraBold",
                                fontWeight: "800",
                                fontSize: "24px",
                                lineHeight: "32px",
                                letterSpacing: "0%",
                            }}
                        >
                            Our Core Values
                        </p>
                        <h2
                            className="down-line w-100 mx-auto mb-4 text-center w-sm-100 header-text"
                        >
                            We are the BEST in property acquisitions
                        </h2>
                        <span
                            className="d-table w-50 w-sm-100 sub-title general-font text-dark fw-normal mx-auto text-center"
                            style={{ fontFamily: "Poppins !important" }}
                        >
                            Trust Landbankng ltd for your real estate transactions. Experience the future of secure and transparent escrow services
                        </span>
                    </div>
                </div>
                <div className="row row-cols-lg-4 row-cols-sm-4 row-cols-1 g-1 justify-content-center">
                    <div className="col">
                        <a
                            href="#"
                            className="text-center d-flex flex-column align-items-center hover-text-dark p-4 bg-white transation text-general h-100"
                        >
                            <div className="box-70px position-relative">
                                <img
                                    src="assets/images/icon/why-choose-us-1.png"
                                    alt="Image Not Found!"
                                />
                            </div>
                            <h6
                                className="d-block text-secondary"
                                style={{
                                    fontFamily: "Abhaya Libre ExtraBold",
                                    fontWeight: "800",
                                    fontSize: "24px",
                                    lineHeight: "30px",
                                    letterSpacing: "1%",
                                    textAlign: "center",
                                    margin: "1rem 0rem",
                                }}
                            >
                                Integrity
                            </h6>
                            <p
                                style={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontFamily: "Poppins !important",
                                    fontWeight: "500",
                                    lineHeight: "22px",
                                }}
                            >
                                At CADLANDS, we prioritise honesty, transparency, and ethical business practices. We believe our word is our bond, and we work hard to ensure every transaction is based on trust, fairness, and accountability. Integrity is not just a value for us; it is the foundation of our reputation.
                            </p>
                        </a>
                    </div>
                    <div className="col">
                        <a
                            href="#"
                            className="text-center d-flex flex-column align-items-center hover-text-dark p-4 bg-white transation text-general h-100"
                        >
                            <div className="box-70px position-relative">
                                <img
                                    src="assets/images/icon/why-choose-us-3.png"

                                    alt="Image Not Found!"
                                />
                            </div>
                            <h6
                                className="d-block text-secondary"
                                style={{
                                    fontFamily: "Abhaya Libre ExtraBold",
                                    fontWeight: "800",
                                    fontSize: "24px",
                                    lineHeight: "30px",
                                    letterSpacing: "1%",
                                    textAlign: "center",
                                    margin: "1rem 0rem",
                                }}
                            >
                                Innovation
                            </h6>
                            <p
                                style={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontFamily: "Poppins !important",
                                    fontWeight: "500",
                                    lineHeight: "22px",
                                }}
                            >
                                Real estate is evolving, and so are we. At CADLANDS, we leverage modern technology and innovative solutions to enhance property ownership. From smart developments to streamlined acquisition processes, we continually push the boundaries of what’s possible.
                            </p>
                        </a>
                    </div>
                    <div className="col">
                        <a
                            href="#"
                            className="text-center d-flex flex-column align-items-center hover-text-dark p-4 bg-white transation text-general h-100"
                        >
                            <div className="box-70px position-relative">
                                <img
                                    src="assets/images/icon/why-choose-us-2.png"
                                    alt="Image Not Found!"
                                />
                            </div>
                            <h6
                                className="d-block text-secondary"
                                style={{
                                    fontFamily: "Abhaya Libre ExtraBold",
                                    fontWeight: "800",
                                    fontSize: "24px",
                                    lineHeight: "30px",
                                    letterSpacing: "1%",
                                    textAlign: "center",
                                    margin: "1rem 0rem",
                                }}
                            >
                                Sustainability
                            </h6>
                            <p
                                style={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontFamily: "Poppins !important",
                                    fontWeight: "500",
                                    lineHeight: "22px",
                                }}
                            >
                                We focus on building for the future with developments that are environmentally friendly, socially impactful, and economically viable. By incorporating sustainable practices and infrastructure, we ensure our communities thrive for generations
                            </p>
                        </a>
                    </div>
                    <div className="col">
                        <a
                            href="#"
                            className="text-center d-flex flex-column align-items-center hover-text-dark p-4 bg-white transation text-general h-100"
                        >
                            <div className="box-70px position-relative">
                                <img
                                    src="assets/images/icon/why-choose-us-4.png"
                                    alt="Image Not Found!"
                                />
                            </div>
                            <h6
                                className="d-block text-secondary"
                                style={{
                                    fontFamily: "Abhaya Libre ExtraBold",
                                    fontWeight: "800",
                                    fontSize: "24px",
                                    lineHeight: "30px",
                                    letterSpacing: "1%",
                                    textAlign: "center",
                                    margin: "1rem 0rem",
                                }}
                            >
                                Customer Focus
                            </h6>
                            <p
                                style={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontFamily: "Poppins !important",
                                    fontWeight: "500",
                                    lineHeight: "22px",
                                }}
                            >
                                Our clients are central to everything we do. Whether you're buying your first home or expanding your investment portfolio, we listen and provide tailored solutions to meet your needs. Your success is our success, and we are committed to making your real estate journey rewarding and seamless
                            </p>
                        </a>
                    </div>
                </div>
            </div>
        </div></>
    )
}

export default OurCoreValues