import './our-commitment.css'
function OurCommitment() {
    return (
        <><div className="full-row">
            <div className="container">
                <div className="row">
                    <div className="col mb-5">
                        <p
                            className="text-center"
                            style={{
                                color: "#0A0A0A",
                                fontFamily: "Abhaya Libre ExtraBold",
                                fontWeight: "800",
                                fontSize: "24px",
                                lineHeight: "32px",
                                letterSpacing: "0%",
                            }}
                        >
                            Our Commitment
                        </p>
                        <h2
                            className="down-line w-75 mx-auto mb-4 text-center w-sm-100 header-text"
                        >
                            Every piece of <span className="text-primary">property</span> holds a promise, and every promise begins with you
                        </h2>
                        {/* <span
                className="d-table w-50 w-sm-100 sub-title general-font text-dark fw-normal mx-auto text-center"
                style={{
                  fontFamily: "Poppins !important",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                }}
              >
                Whether it's an acquisition, Verification or a detailed
                discussion about your property goals, we're here to make your
                experience seamless
              </span> */}
                    </div>
                </div>
                <div className="row row-cols-lg-5 row-cols-sm-4 row-cols-1 g-3 justify-content-center">
                    <div className="col">
                        <a
                            href="#"
                            className="text-center d-flex flex-column align-items-center hover-text-dark p-4 bg-white transation text-general h-100"
                        >
                            <div className="box-70px position-relative">
                                <img
                                    src="assets/images/icon/service-icon-1.png"
                                    alt="Image Not Found!"
                                />
                            </div>
                            <h6
                                className="d-block text-secondary"
                                style={{
                                    fontFamily: "Poppins !important",
                                    fontWeight: "600",
                                    fontSize: "28px",
                                    lineHeight: "36.4px",
                                    letterSpacing: "-1%",
                                    textAlign: "center",
                                    margin: "1.5rem 0rem",
                                }}
                            >
                                Peace
                            </h6>
                            <p
                                style={{
                                    color: "#000",
                                    fontSize: "16px",
                                    fontFamily: "Mulish !important",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                }}
                            >
                                We believe that genuine property ownership involves more than just acquiring land or a building; it encompasses security, stability, and peace of mind. That’s why we prioritise making every transaction seamless, transparent, and legally sound, helping to eliminate the stress and uncertainties often associated with real estate.
                            </p>
                        </a>
                    </div>
                    <div className="col">
                        <a
                            href="#"
                            className="text-center d-flex flex-column align-items-center hover-text-dark p-4 bg-white transation text-general h-100"
                        >
                            <div className="box-70px position-relative">
                                <img
                                    src="assets/images/icon/service-icon-2.png"
                                    alt="Image Not Found!"
                                />
                            </div>
                            <h6
                                className="d-block text-secondary"
                                style={{
                                    fontFamily: "Poppins !important",
                                    fontWeight: "600",
                                    fontSize: "28px",
                                    lineHeight: "36.4px",
                                    letterSpacing: "-1%",
                                    textAlign: "center",
                                    margin: "1.5rem 0rem",
                                }}
                            >
                                Profit
                            </h6>
                            <p
                                style={{
                                    color: "#000",
                                    fontSize: "16px",
                                    fontFamily: "Mulish !important",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                }}
                            >
                                Real estate is a powerful tool for building wealth, and we are dedicated to helping our clients maximise their investments. Whether you are purchasing for personal use or financial growth, our developments are designed to provide long-term value and impressive returns.
                            </p>
                        </a>
                    </div>
                    <div className="col">
                        <a
                            href="#"
                            className="text-center d-flex flex-column align-items-center hover-text-dark p-4 bg-white transation text-general h-100"
                        >
                            <div className="box-70px position-relative">
                                <img
                                    src="assets/images/icon/service-icon-3.png"
                                    alt="Image Not Found!"
                                />
                            </div>
                            <h6
                                className="d-block text-secondary"
                                style={{
                                    fontFamily: "Poppins !important",
                                    fontWeight: "600",
                                    fontSize: "28px",
                                    lineHeight: "36.4px",
                                    letterSpacing: "-1%",
                                    textAlign: "center",
                                    margin: "1.5rem 0rem",
                                }}
                            >
                                Progress
                            </h6>
                            <p
                                style={{
                                    color: "#000",
                                    fontSize: "16px",
                                    fontFamily: "Mulish !important",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                }}
                            >
                                We are not merely offering easy access to properties; we are shaping the future. Our commitment to progress inspires us to create innovative, sustainable, and well-planned communities. By promoting modern infrastructure, inclusive growth, and sustainable solutions, CADLANDS is paving the way for a more developed and prosperous Nigeria
                            </p>
                        </a>
                    </div>
                </div>
            </div>
        </div></>
    )
}

export default OurCommitment